import { useCallback, useMemo } from 'react';
import type { GetPageInfo_pageInfo } from '@marty-js/api-sdk/__generated__/GetPageInfo';
import { useAdContext } from '@marty-js/sdk/src/ads/AdContext';
import adUnitConfig, { adUnitStickyTop } from './adUnitConfig';

import { config as siteConfig } from '../../config';

const baseUnit: string = '/21718639124/clubic.com/';

export const useOverrideAdUnit = () => {
  const adContext = useAdContext();

  return useCallback(
    (overrideAdUnit) => {
      if (adContext.shortAdUnit !== overrideAdUnit) {
        adContext.setShortAdUnit(overrideAdUnit);
        adContext.adUnit = `${baseUnit}${overrideAdUnit}`;
      }
    },
    [adContext],
  );
};

export const useClubicAds = (pageInfo: GetPageInfo_pageInfo, overrideAdUnit: string = null) => {
  const shortAdUnit: string = useMemo<string>(() => {
    if (overrideAdUnit) {
      return overrideAdUnit;
    }

    if (pageInfo?.url in adUnitConfig) {
      return adUnitConfig[pageInfo?.url];
    }
    if (pageInfo?.layout in adUnitConfig) {
      return adUnitConfig[pageInfo?.layout];
    }

    if (pageInfo?.dataLayout in adUnitConfig) {
      return adUnitConfig[pageInfo?.dataLayout];
    }

    return adUnitConfig[pageInfo?.pageType];
  }, [pageInfo, overrideAdUnit]);

  const adContext = useAdContext();

  const tags = useMemo(() => {
    const arTags: string[] = [];
    pageInfo?.tagSlugs?.forEach((tag) => {
      arTags.push(tag);
    });

    const breadcrumb = pageInfo?.breadcrumb || [];
    breadcrumb.slice(breadcrumb.length > 1 ? 1 : 0).forEach((section) => {
      arTags.push(section.title);
      arTags.push(`section-${section.id}`);
    });

    return arTags;
  }, [pageInfo.breadcrumb, pageInfo.tagSlugs]);

  if (adContext) {
    adContext.site = 'clubicv3';
    adContext.adUnit = `${baseUnit}${shortAdUnit}`;
    adContext.tags = tags || adContext?.tags;
    adContext.isSafe = true;
    if (adUnitStickyTop.includes(shortAdUnit) && adContext.tags.includes('nopub') === false) {
      adContext.stickyTop = true;
    }
    adContext.env = siteConfig.addEnv;
    if (adContext.setReloadScript && overrideAdUnit) {
      adContext.setReloadScript(true);
    }
    adContext.setShortAdUnit(shortAdUnit);
  }
};
